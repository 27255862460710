<template>
  <div class="upload">
    <div class="img" v-for="(item, index) in attach" :key="index" @click="viewImage(index, item)">
      <img :src="item.url" v-if="item.fileType == 'image'" />
      <!-- <video :src="item.url" v-else /> -->
      <img :src="videoUploadDone" v-else />
      <img class="del-icon" :src="delIcon" alt="" @click.stop="delImage(index, item)" v-if="readonly == false" />
    </div>

    <div class="add-image" @click="uploadImg" v-if="isUploading == false && readonly == false">
      <img :src="addIcon" alt="" />

      <input multiple ref="image" type="file" name="" id="fileInput" style="display: none" @change="handleUpload" />
    </div>
    <div class="add-progress" v-show="isUploading">
      {{ totalPercent }}
    </div>
    <!-- <ul class="img-container">
      <li v-for="(item, index) in attach" :key="index">
        <img class="show-img" :src="item.url" />
      </li>
      <li v-for="(item, index) in attachArr" :key="index">
        <span class="progress" v-if="item.url == ''">{{ item.percent }}%</span>
        <span class="repeat-img" @click="delImg(index)">
          <img src="../assets/images/del-img.png" alt="" />
        </span>
        <img class="show-img" :src="item.url" />
      </li>
      <li @click="uploadImg()">
        <div class="img-upload">
          <img src="../assets/images/add-img.png" alt="" />
          <input
            multiple
            ref="image"
            type="file"
            name=""
            id="fileInput"
            style="display: none"
            @change="handleSuccess"
          />
        </div>
      </li>
    </ul> -->
    <van-popup v-model="showVideo" round position="bottom" :style="{ height: '70%' }">
      <div class="pop-container">
        <video class="pop-video" :src="videoPlayingUrl" controls="controls" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { matchFileType } from "@/util/validate";
import { ImagePreview } from "vant";
import { userLearnPlanApi } from "@/api/userLearnPlanApi.js";
import { userHomeWorkApi } from "@/api/userHomeWorkApi.js";
import { userRemarkApi } from "@/api/userRemarkApi.js";
import { courseExtendApi } from "@/api/courseExtendApi.js";
import { cosApi } from "@/api/cosApi.js";
import { Toast } from "vant";
import COS from "cos-js-sdk-v5"; //脚手架安装
import { nanoid } from "nanoid";
import { Notify } from "vant";

export default {
  components: {},
  model: {
    prop: "attach",
    event: "onAddMedia",
  },
  props: {
    attach: Array,
    readonly: Boolean,
    bizType: String,
  },
  data() {
    return {
      addIcon: require("@/assets/images/upload.png"),
      delIcon: require("@/assets/images/del-img.png"),
      videoUploadDone: require("@/assets/images/video_upload_done.png"),
      cosPrefix: "",
      bucket: "",
      region: "",
      fileDoneCnt: 0,
      cosStsUrl: "",

      isUploading: false,
      todoFiles: [], //上传和接收回调
      totalPercent: 0,

      showVideo: false,
      videoPlayingUrl: "",
    };
  },
  methods: {
    //删除图片
    delImage(index, item) {
      //console.log(index, item);
      let retList = [...this.attach];
      retList.splice(index, 1);
      //console.log("emit", retList);
      this.$emit("onAddMedia", retList); //emit 事件，父组件更新，再传给子组件
    },
    viewImage(index, item) {
      if (item.fileType == "image") {
        ImagePreview({ images: [item.url], closeable: true });
      } else if (item.fileType == "video") {
        // this.showVideo = true;
        // this.videoPlayingUrl = item.url;
        Notify({
          type: "success",
          message: "视频已上传",
        });
      }
    },
    getBucketRegion() {
      cosApi.getCosBucketRegion().then((res) => {
        // console.log(res);
        if (res.code == 0) {
          let data = res.data;
          this.bucket = data.bucket;
          this.region = data.region;
        }
        // console.log(this.bucket);
        // console.log(this.region);
      });
    },
    getCosPrefix() {
      if (this.bizType == "plan") {
        userLearnPlanApi.cosPrefix().then((res) => {
          if (res.code == 0) {
            this.cosPrefix = res.data;
          }
          //console.log(this.cosPrefix);
        });
      } else if (this.bizType == "home-work") {
        // home-work
        userHomeWorkApi.cosPrefix().then((res) => {
          if (res.code == 0) {
            this.cosPrefix = res.data;
          }
          //console.log(this.cosPrefix);
        });
      } else if (this.bizType == "user-operation") {
        // 用户星球
        userRemarkApi.cosPrefix().then((res) => {
          if (res.code == 0) {
            this.cosPrefix = res.data;
          }
          //console.log(this.cosPrefix);
        });
      } else if (this.bizType == "user_course_extend_review") {
        courseExtendApi.cosPrefix().then((ret) => {
          if (ret.code == 0) {
            this.cosPrefix = ret.data;
          }
        });
      }
    },
    getCosStsUrl() {
      const url = cosApi.getCosStsUrl();
      this.cosStsUrl = url;
    },
    // 点击请求input的click()事件
    uploadImg() {
      this.$refs.image.click();
    },
    // 用户选择好文件
    handleUpload(e) {
      let _this = this;
      console.log(e.target.files, "上传文件");
      //this.getCosPrefix(); 在mounted初始化就行了，不需要再取一次了
      var cosClient = new COS({
        getAuthorization: function(options, callback) {
          //const COS_STS_URL = `/api/itedu/v1/cos/appid`;
          console.log("sts url", _this.cosStsUrl);
          var url = _this.cosStsUrl;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function(e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {
              console.log(e);
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              ExpiredTime: data.expiredTime,
            });
          };
          xhr.send();
        },
      });

      let fileList = [];
      this.todoFiles = [];
      const maxsize = 8000 * 1024 * 1024; // 8gb
      let userSelectFiles = e.target.files;
      for (let i = 0; i < userSelectFiles.length; i++) {
        if (matchFileType(userSelectFiles[i].name) != "image" && matchFileType(userSelectFiles[i].name) != "video") {
          Toast.fail("文件格式错误，请重新上传!");
          return;
        }
        if (userSelectFiles[i].size > maxsize) {
          Toast.fail("文件超过8GB，无法支持大文件上传!");
          return;
        }
        fileList.push(userSelectFiles[i]);
      }

      for (let item of fileList) {
        let suffix = "";
        try {
          let fileName = item.name;
          // 截取文件后缀
          suffix = fileName.substr(fileName.lastIndexOf(".") + 1, fileName.length);
          // 文件后缀转小写，方便匹配
          suffix = suffix.toLowerCase();
        } catch (err) {
          suffix = "";
        }
        let id = nanoid(12);
        this.todoFiles.push({
          id: id,
          file: item,
          cosKey: this.cosPrefix + "/" + id + "." + suffix,
          percent: 0,
          url: "",
          key: "",
        });
      }

      console.log("todofiles....", this.todoFiles);
      this.isUploading = true;
      //逐个上传
      for (let i = 0; i < this.todoFiles.length; i++) {
        // let file = this.folderFiles[i];
        // let fileName = this.folderFiles[i].name;
        // let webkitRelativePath = this.folderFiles[i].webkitRelativePath;
        console.log("...upload one file", this.todoFiles[i]);
        // 分片上传文件
        let _this = this;
        const slice = cosClient.sliceUploadFile(
          {
            Bucket: this.bucket,
            Region: this.region,
            Key: this.todoFiles[i].cosKey,
            Body: this.todoFiles[i].file,
            onTaskReady: function(taskId) {
              /* 非必须 */
              console.log(taskId);
            },
            onHashProgress: function(progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
            },
            onProgress: function(progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
              _this.todoFiles[i].percent = progressData.percent.toFixed(2) * 100;
              _this.updateTotalPercent();
            },
          },
          function(err, data) {
            console.log("...", err, data);
            if (data.statusCode == 200) {
              console.log("..done..", data);
              console.log("..key..", data.Key); // cos key 在这
              userLearnPlanApi.cdnUrl(data.Key).then((ret) => {
                console.log(ret); // cdn url 在这
                if (ret.code == 0) {
                  _this.todoFiles[i].key = data.Key;
                  _this.todoFiles[i].url = ret.data;
                  console.log("###", i, _this.todoFiles);
                  _this.onUploadDone();
                }
              });
            }
          }
        );
      }
    },
    onUploadDone() {
      // 上传成功后，emit数据
      let allDone = true;
      for (let item of this.todoFiles) {
        if (item.percent < 100) {
          allDone = false;
          break;
        }
      }
      if (allDone) {
        let retList = [...this.attach];
        for (let item of this.todoFiles) {
          retList.push({
            key: item.key,
            url: item.url,
            fileType: matchFileType(item.key),
          });
        }
        console.log("emit", retList);
        this.$emit("onAddMedia", retList);
        this.isUploading = false;
      }
    },
    updateTotalPercent() {
      // 简单实现进度
      if (this.isUploading) {
        let total = 0;
        let cur = 0;
        for (let item of this.todoFiles) {
          cur += item.percent;
          total += 100;
        }
        this.totalPercent = (cur / total) * 100;
        this.totalPercent = this.totalPercent.toFixed(0);
        this.totalPercent = this.totalPercent + "%";
      }
    },
  },
  mounted() {
    this.getCosPrefix();
    this.getBucketRegion();
    this.getCosStsUrl();
    //console.log("upload mounted this.attach", this.attach);
  },
  watch: {
    // attach: function(newVal) {
    //   console.log("watch attach", newVal);
    // },
    showVideo: function(newVal) {
      if (newVal == false) {
        this.videoPlayingUrl = "";
      }
    },
  },
  destroyed() {},
  filters: {},
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .img {
    height: 54px;
    width: 54px;
    margin: 12px 12px 0px 0px;
    position: relative; // 父节点要设置relative
    border-radius: 8px;
    overflow: hidden;
    img,
    video {
      height: 54px;
      width: 54px;
    }

    .del-icon {
      position: absolute; // 相对于 static 定位以外的第一个父元素进行定位,如果找不到父级元素，那么相对于浏览器窗口进行定位 。
      z-index: 1;
      top: 0px;
      right: 0px;
      width: 16px;
      height: 16px;
    }
  }
  .add-image {
    height: 54px;
    width: 54px;
    margin: 12px 12px 0px 0px;
    img {
      height: 54px;
      width: 54px;
    }
  }
  .add-progress {
    height: 54px;
    width: 54px;
    margin: 12px 12px 0px 0px;
    background: #d8d8d8;
    border-radius: 8px;
    color: #505051;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.pop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .pop-video {
    width: 90%;
    margin: 20px;
  }
}
</style>
