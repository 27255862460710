import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const add = (remark) => {
  return ajax.post(`${base}/api/itedu/v1/user-remark/add`, remark);
};

const get = (taskId, id) => {
  return ajax.get(`${base}/api/itedu/v1/user-remark/get?taskId=${taskId}&id=${id}`);
};

const pager = (taskId, start, len) => {
  return ajax.get(`${base}/api/itedu/v1/user-remark/pager?taskId=${taskId}&start=${start}&len=${len}`);
};

const cosPrefix = () => {
  return ajax.get(`${base}/api/itedu/v1/user-remark/cos-prefix`);
};

export const userRemarkApi = {
  // import时得花括号明确
  add: add,
  get: get,
  pager: pager,
  cosPrefix: cosPrefix,
};
