<template>
  <div class="container">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        用户星球
      </div>
      <div class="right"></div>
    </div>
    <div class="phone-block" contenteditable="false">
      {{ currentUser.phone }}
    </div>
    <div class="text-block" contenteditable="true" placeholder="微信号" @input="onWechatInput"></div>
    <div class="main" contenteditable="true" placeholder="请输入内容" @input="onUserContInput"></div>
    <div class="upload-block">
      <upload v-model="remark.userAttach" :readonly="false" :bizType="bizType"> </upload>
    </div>
    <div class="footer">
      <button class="save-btn" @click="onAddOrEdit">
        保存
      </button>
    </div>
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
import csWidget from "@/components/csWidget.vue";
import activeCourseWidget from "@/components/activeCourseWidget.vue";
import logoutWidget from "@/components/logoutWidget.vue";
import { Notify } from "vant";
import { userRemarkApi } from "@/api/userRemarkApi.js";
import { userCenterApi } from "@/api/userCenterApi.js";
import upload from "@/components/upload.vue";
import { matchFileType } from "@/util/validate";
export default {
  components: {
    upload,
  },
  data() {
    return {
      bizType: "user-operation",

      goBackImg: require("@/assets/images/go-back.png"),
      currentUser: {
        phone: "",
        userId: "",
      },
      taskId: 0,
      remark: {
        taskId: 0,
        id: 0,
        userId: 0,
        userPhone: 0,
        userExtra: {
          wechat: "",
          courseList: [],
        },
        userAttach: [],
        teacherCont: "",
        teacherAttach: [],
        show: true,
        createTime: 0,
        updateTime: 0,
        audit: "",
      },
    };
  },
  methods: {
    onGoBack() {
      this.$router.push({
        path: "userRemark",
        query: {
          taskId: this.taskId,
        },
      });
    },
    onWechatInput(e) {
      this.remark.userExtra.wechat = e.target.innerText;
    },
    onUserContInput(e) {
      this.remark.userCont = e.target.innerText;
    },
    onUid() {
      userCenterApi.uid().then((uidRet) => {
        console.log(uidRet);
        if (uidRet.code == 0 && uidRet.data) {
          this.currentUser = uidRet.data;
          // remark init
          this.remark.userId = this.currentUser.userId;
          this.remark.userPhone = this.currentUser.phone;
        } else {
          Notify({
            type: "danger",
            message: "获取用户信息失败",
          });
        }
      });
    },
    getTaskId() {
      if (this.$route.query && this.$route.query.taskId) {
        this.taskId = this.$route.query.taskId;
        this.remark.taskId = this.taskId;
      }
    },
    wrapAttach(itemList) {
      for (let item of itemList) {
        item.fileType = matchFileType(item.key);
      }
    },
    onAddOrEdit() {
      console.log("save", this.remark);
      userRemarkApi
        .add(this.remark)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0) {
            Notify({
              type: "success",
              message: "评论成功，感谢家人们、同学们～ ヽ(✿ﾟ▽ﾟ)ノ",
            });
            this.remark = data;
            console.log("add ok...", this.remark);
            this.$nextTick(() => {
              this.onGoBack();
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Notify({
            type: "danger",
            message: "评论失败，可能是服务器睡着了",
          });
        });
    },
  },
  mounted() {
    this.onUid();
    this.getTaskId();
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    // display: flex;
    // flex-direction: column;
    // height: 62px;
    // background: #ffffff;
    // font-size: 18px;
    // color: #323233;
    // letter-spacing: 0.4px;
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    font-size: 14px;
    color: #323233;
    letter-spacing: 0.31px;
    margin: 16px;
    background: #ffffff;
    border-radius: 8px;
    height: 200px;
    padding: 8px;
    /* 输入框为空时显示 placeholder */
    &:empty:before {
      content: attr(placeholder);
    }
    /* 输入框获取焦点时移除 placeholder */
    &:focus:before {
      content: none;
    }
  }
  .phone-block {
    margin: 14px 16px 0px 16px;
    height: 36px;
    border-radius: 8px;
    padding: 0px 11px;
    font-size: 16px;
    line-height: 36px;
    color: #9298a4;
    letter-spacing: 0.36px;
    background: #ffffff;
  }
  .text-block {
    margin: 14px 16px 0px 16px;
    height: 36px;
    border-radius: 8px;
    padding: 0px 11px;
    font-size: 16px;
    line-height: 36px;
    color: #505051;
    letter-spacing: 0.36px;
    background: #ffffff;
    &:empty:before {
      content: attr(placeholder);
    }
    /* 输入框获取焦点时移除 placeholder */
    &:focus:before {
      content: none;
    }
  }
  .upload-block {
    margin: 14px 16px 0px 16px;
  }
  .footer {
    margin: 16px;
    display: flex;
    flex-direction: row-reverse;
    .save-btn {
      background: #4d52d1;
      border-radius: 14px;
      width: 110px;
      height: 40px;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      line-height: 30px;
      border: none;
    }
  }
}
</style>
